const BANNER_1 = [
  {
    id: 1,
    path: "banner-1-heading.png",
    title: "TFGaming",
    class:"heading"
  },
  {
    id: 2,
    path: "banner-1-heading-sub.png",
    title: "Asia's leading esports platform provider",
    class:"sub-heading"
  },
];
const BANNER_2 = [
  {
    id: 1,
    path: "banner-2-title.png",
    title: "TFGaming",
    class:"title"
  },
  {
    id: 2,
    path: "banner-2-heading.png",
    title: "Pursuit of best gaming",
    class:"heading"
  },
  {
    id: 3,
    path: "banner-2-heading-sub.png",
    title: "Experience",
    class:"sub-heading"
  },
];

const BANNER_3 = [
  {
    id: 1,
    path: "banner-3-title.png",
    title: "Tf exclusive",
    class:"title"
  },
  {
    id: 2,
    path: "banner-3-heading.png",
    title: "Lootbox",
    class:"heading"
  },
  {
    id: 3,
    path: "banner-3-heading-sub.png",
    title: "Unbiased fair game, future of gaming",
    class:"sub-heading"
  }
];
const BANNER_3IMG = [
  {
    id: 4,
    path: "banner-3-img-1.png",
    title: "banner",
    class:"loot1"
  },
  {
    id: 5,
    path: "banner-3-img-2.png",
    title: "banner",
    class:"loot2"
  },
  {
    id: 6,
    path: "banner-3-img-3.png",
    title: "banner",
    class:"loot3"
  },
];
const BANNER_4 = [
    {
      id: 2,
      path: "banner-4-heading.png",
      title: "Brand new interface",
      class:"heading"
    },
    {
      id: 3,
      path: "banner-4-heading-sub.png",
      title: "New currencies supported",
      class:"sub-heading"
    }
  ];
  const BANNER_4IMG = [
    {
      id: 4,
      path: "banner-4-img-1.png",
      title: "banner",
      class:"loot-1"
    },
    {
      id: 5,
      path: "banner-4-img-2.png",
      title: "banner",
      class:"loot-2"
    },
    {
      id: 6,
      path: "banner-4-coins.png",
      title: "coins",
      class:"coins"
    },
    {
      id: 7,
      path: "banner-4-btc.png",
      title: "btc",
      class:"btc"
    },
    {
      id: 8,
      path: "banner-4-eth.png",
      title: "eth",
      class:"eth"
    },
    {
        id: 9,
        path: "banner-4-usdt.png",
        title: "usdt",
        class:"usdt"
      },
  ];
  const MOBILE_BANNER = [
    {
      id: 1,
      path: "banner_1.png",
      title: "banner_1",
      class:"cover"
    },
    {
      id: 2,
      path: "banner_2.png",
      title: "banner_2",
      class:"cover"
    },
    {
      id: 3,
      path: "banner_3.png",
      title: "banner_3",
      class:"cover"
    },
    {
      id: 4,
      path: "banner_4.png",
      title: "banner_4",
      class:"cover"
    },
  ]
  
export { BANNER_1, BANNER_2, BANNER_3, BANNER_4, MOBILE_BANNER,BANNER_3IMG, BANNER_4IMG };
