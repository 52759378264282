import "@/styles/mobile/lootbox_mobile.scss";
import Reveal from "react-awesome-reveal";
import {
  FADE_LEFT_RIGHT,
  FADE_RIGHT_LEFT,
  FADE_TOP_BOTTOM,
} from "@/utils/constant/keyframes";
import { getAssets, getActiveLang } from "@/utils/helpers";
import { useTranslation } from "react-i18next";

const MobileLootbox = () => {
  const { t } = useTranslation();

  return (
    <div className="mobile_lootbox-features">
      <div className="features">
        <div className="features-devices">
          <Reveal keyframes={FADE_TOP_BOTTOM}>
            <img
              src={require("@/" +
                getAssets("feature-device.png", false, false))}
              alt="device"
            />
          </Reveal>
        </div>
        <div>
          <ul className="features-list info">
            <Reveal delay={50} keyframes={FADE_LEFT_RIGHT}>
              <li>
                <span
                  className={getActiveLang() === "zh" ? "span_disabled" : ""}
                >
                  {" "}
                  {t("Unbeatable odds speedy settlement")}
                </span>
                <span
                  className={getActiveLang() === "en" ? "span_disabled" : ""}
                >
                  {t("Unbeatable odds speedy settlement")
                    .split(",")
                    .map((text1, index) => (
                      <div key={index}>
                        {text1 + (index === 0 ? ", " : "")}
                        {"\n"}
                      </div>
                    ))}
                </span>
              </li>
            </Reveal>
            <Reveal delay={150} keyframes={FADE_LEFT_RIGHT}>
              <li>
                <span
                  className={getActiveLang() === "zh" ? "span_disabled" : ""}
                >
                  {" "}
                  {t("Extensive choices of esports markets")}
                </span>
                <span
                  className={getActiveLang() === "en" ? "span_disabled" : ""}
                >
                  {t("Extensive choices of esports markets")
                    .split(",")
                    .map((text2, index) => (
                      <div key={index}>
                        {text2 + (index === 0 ? ", " : "")}
                        {"\n"}
                      </div>
                    ))}{" "}
                </span>
              </li>
            </Reveal>
            <Reveal delay={300} keyframes={FADE_LEFT_RIGHT}>
              <li>
                <span>
                  {t("Vastly experienced and skilled esports traders")}
                </span>
              </li>
            </Reveal>
            <Reveal delay={50} keyframes={FADE_RIGHT_LEFT}>
              <li>
                <span
                  className={getActiveLang() === "zh" ? "span_disabled" : ""}
                >
                  {" "}
                  {t("Fast and stable system, double betting speed")}
                </span>
                <span
                  className={getActiveLang() === "en" ? "span_disabled" : ""}
                >
                  {t("Fast and stable system, double betting speed")
                    .split(",")
                    .map((text4, index) => (
                      <div key={index}>
                        {text4 + (index === 0 ? ", " : "")}
                        {"\n"}
                      </div>
                    ))}
                </span>
              </li>
            </Reveal>
            <Reveal delay={150} keyframes={FADE_RIGHT_LEFT}>
              <li>
                <span
                  className={getActiveLang() === "zh" ? "span_disabled" : ""}
                >
                  {" "}
                  {t("Supports pcs, mobile phones and tablets")}
                </span>
                <span
                  className={getActiveLang() === "en" ? "span_disabled" : ""}
                >
                  {t("Supports pcs, mobile phones and tablets")
                    .split(",")
                    .map((text5, index) => (
                      <div key={index}>
                        {text5 + (index === 0 ? ", " : "")}
                        {"\n"}
                      </div>
                    ))}
                </span>
              </li>
            </Reveal>
            <Reveal delay={300} keyframes={FADE_RIGHT_LEFT}>
              <li>
                <span>{t("Industry leading betting experience")}</span>
              </li>
            </Reveal>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileLootbox;
