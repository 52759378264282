import "@/styles/sections/marquee.scss";
import { getAssets } from "@/utils/helpers";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

const MarqueeImage = () => {
  const imagesRight = [
    { id: 1, path: "marquee/kok1.png", title: "kok1" },
    { id: 2, path: "marquee/yibo.png", title: "yibo" },
    { id: 3, path: "marquee/betway.png", title: "betway" },
    { id: 4, path: "marquee/tcGaming.png", title: "tcGaming" },
    { id: 5, path: "marquee/fun88.png", title: "fun88" },
    { id: 6, path: "marquee/gamingSoft.png", title: "gamingSoft" },
    { id: 7, path: "marquee/idnPlay.png", title: "idnPlay" },
    { id: 8, path: "marquee/newBet.png", title: "newBet" },
  ];
  const imagesLeft = [
    { id: 1, path: "marquee/jbo.png", title: "jbo" },
    { id: 2, path: "marquee/goldenF.png", title: "goldenF" },
    { id: 3, path: "marquee/world-network.png", title: "world-network" },
    { id: 4, path: "marquee/mansion8.png", title: "mansion8" },
    { id: 5, path: "marquee/hongtu.png", title: "hongtu" },
    { id: 6, path: "marquee/wb.png", title: "wb" },
    { id: 7, path: "marquee/m88.png", title: "m88" },
    { id: 8, path: "marquee/hongtu2.png", title: "hongtu2" },
    { id: 9, path: "marquee/wb2.png", title: "wb2" },
  ];
  return (
    <div className="marquee-box">
      <Marquee direction="right" speed="30" behavior="scroll">
        <div className="marquee-image">
          <Link to="#">
            {imagesRight.map((items) => (
              <img
                className="group"
                key={items.id}
                src={require("@/" + getAssets(items.path, false, false))}
                title="items.title"
                alt="marqueeImage"
              ></img>
            ))}
          </Link>
        </div>
      </Marquee>
      <Marquee direction="left" speed="30" behavior="scroll">
        <div className="marquee-image">
          <Link to="#">
            {imagesLeft.map((items) => (
              <img
                className="group"
                key={items.id}
                src={require("@/" + getAssets(items.path, false, false))}
                title="items.title"
                alt="marqueeImage"
              ></img>
            ))}
          </Link>
        </div>
      </Marquee>
    </div>
  );
};

export default MarqueeImage;
