const BREAKPOINTS = {
    desktop: 1440,
    ipad_pro: 1024,
    ipad_pro_portrait: 1366,
    ipad: 768,
    mobile: 429,
};

const IMAGE_ASSETS = 'assets/images';

const LANGUAGES = ['zh', 'en'];

const DEFAULT_LANGUAGE = 'zh';

const FLAG_LIST = [
    {
        id: 'zh',
        label: 'Zh',
        icon: 'flag-zh.png'
    },
    {
        id: 'en',
        label: 'En',
        icon: 'flag-en.png'
    }
];

export {
    BREAKPOINTS,
    IMAGE_ASSETS,
    LANGUAGES,
    DEFAULT_LANGUAGE,
    FLAG_LIST
}