import "@/styles/sections/events.scss";
import "@/styles/mobile/events_mobile.scss";
import { useTranslation } from "react-i18next";
import { getAssets } from "@/utils/helpers";
import Reveal from "react-awesome-reveal";
import { Modal } from "react-bootstrap";
import { FADE_TOP_BOTTOM } from "@/utils/constant/keyframes";
import Slider from "react-slick";
import React, { useState } from "react";
import ice from "@/components/videos/ice_london.mp4";
import g2e from "@/components/videos/g2e-assia.mp4";
import ReactPlayer from "react-player";
import useGAEventsTracker from "../../hooks/useGAEventsTracker";
import { useDimensionTracker } from "../../hooks/useDimensionsTracker";
import { getActiveLang } from "@/utils/helpers";

const Events = () => {
  const { t } = useTranslation();
  const dimensions = useDimensionTracker();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          swipe: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: true,
        },
      },
    ],
  };

  const GAEventsTracker = useGAEventsTracker("Events Collection");

  const [IcemodalIsOpen, setIsOpenIce] = useState(false);
  const [G2EmodalIsOpen, setIsOpenG2e] = useState(false);
  const divStyle = {
    borderRadius: 40,
  };
  const imagesIce = [
    { id: 1, path: "tf-ice/1.jpg", title: "ice1" },
    { id: 2, path: "tf-ice/2.jpg", title: "ice2" },
    { id: 3, path: "tf-ice/3.jpg", title: "ice3" },
    { id: 4, path: "tf-ice/4.jpg", title: "ice4" },
    { id: 5, path: "tf-ice/5.jpg", title: "ice5" },
    { id: 6, path: "tf-ice/6.jpg", title: "ice6" },
    { id: 7, path: "tf-ice/7.jpg", title: "ice7" },
    { id: 8, path: "tf-ice/8.jpg", title: "ice8" },
    { id: 9, path: "tf-ice/9.jpg", title: "ice9" },
    { id: 10, path: "tf-ice/10.jpg", title: "ice10" },
  ];
  const imagesG2E = [
    { id: 1, path: "tf-g2e/1.jpg", title: "g2e1" },
    { id: 2, path: "tf-g2e/2.jpg", title: "g2e2" },
    { id: 3, path: "tf-g2e/3.jpg", title: "g2e3" },
    { id: 4, path: "tf-g2e/4.jpg", title: "g2e4" },
    { id: 5, path: "tf-g2e/5.jpg", title: "g2e5" },
    { id: 6, path: "tf-g2e/6.jpg", title: "g2e6" },
    { id: 7, path: "tf-g2e/7.jpg", title: "g2e7" },
    { id: 8, path: "tf-g2e/8.jpg", title: "g2e8" },
    { id: 9, path: "tf-g2e/9.jpg", title: "g2e9" },
    { id: 10, path: "tf-g2e/10.jpg", title: "g2e10" },
  ];

  return (
    <div id="Events" className="gallery-slide">
      <div className="gallery-slide-wrap">
        <Reveal keyframes={FADE_TOP_BOTTOM}>
          <Slider {...settings}>
            <div className="inlines">
              <div className="inline-image">
                <ReactPlayer
                  id="player1"
                  url={ice}
                  playsinline={true}
                  playing={true}
                  width="100%"
                  controls={true}
                  loop={true}
                  style={divStyle}
                  playIcon={
                    <div
                      className="play-icon"
                      role="button"
                      tabIndex={0}
                      style={{ outline: "none" }}
                    >
                      <img
                        className="play-btn"
                        src={require("@/" +
                          getAssets("play-btn.png", false, false))}
                        alt="play"
                        onClick={(e) =>
                          GAEventsTracker("Play Ice London Video")
                        }
                      />
                    </div>
                  }
                  light={require("@/" +
                    getAssets("ice-video-img.png", false, false))}
                />
              </div>
              {dimensions > 750 ? (
                <div className="event-name">
                  <h2 className="heading">{t("Ice london 2020")}</h2>
                  <p
                    className={`${
                      getActiveLang() === "zh" ? "disabled" : "sub-heading"
                    }`}
                  >
                    {t("Thank you for your")}
                  </p>
                  <p
                    className={`${
                      getActiveLang() === "zh" ? "disabled" : "sub-heading-two"
                    }`}
                  >
                    {t("Support")}
                  </p>
                  <p
                    className={`${
                      getActiveLang() === "zh" ? "sub-heading-two" : "disabled"
                    }`}
                  >
                    {t("Thank you for your support")}
                  </p>
                  <p
                    className="photo-link"
                    onClick={() => {
                      setIsOpenIce(true);
                      GAEventsTracker("View Ice London Photo Gallery");
                    }}
                  >
                    {t("View photo gallery")}
                  </p>
                </div>
              ) : (
                dimensions <= 750 && (
                  <div className="event-name">
                    <h2 className="heading">{t("Ice london 2020")}</h2>
                    <p
                      className={`${
                        getActiveLang() === "zh" ? "disabled" : "sub-heading"
                      }`}
                    >
                      {t("Thank you for")}
                    </p>
                    <p
                      className={`${
                        getActiveLang() === "zh"
                          ? "disabled"
                          : "sub-heading-two"
                      }`}
                    >
                      {t("Your support")}
                    </p>
                    <p
                      className={`${
                        getActiveLang() === "zh"
                          ? "sub-heading-two"
                          : "disabled"
                      }`}
                    >
                      {t("Thank you for your support")}
                    </p>
                    <p
                      className="photo-link"
                      onClick={() => {
                        setIsOpenIce(true);
                        GAEventsTracker("View Ice London Photo Gallery");
                      }}
                    >
                      {t("View photo gallery")}
                    </p>
                  </div>
                )
              )}
            </div>
            <div className="inlines">
              <div className="inline-image">
                <ReactPlayer
                  id="player2"
                  url={g2e}
                  playsinline={true}
                  playing={true}
                  width="100%"
                  controls={true}
                  loop={true}
                  style={divStyle}
                  playIcon={
                    <div
                      className="play-icon"
                      role="button"
                      tabIndex={0}
                      style={{ outline: "none" }}
                    >
                      <img
                        className="play-btn"
                        src={require("@/" +
                          getAssets("play-btn.png", false, false))}
                        alt="play"
                        onClick={(e) => GAEventsTracker("Play G2E Asia Video")}
                      />
                    </div>
                  }
                  light={require("@/" +
                    getAssets("g2e-asia.png", false, false))}
                />
              </div>

              {dimensions > 750 ? (
                <div className="event-name">
                  <h2 className="heading">{t("G2e asia 2019 philippines")}</h2>
                  <p
                    className={`${
                      getActiveLang() === "zh" ? "disabled" : "sub-heading"
                    }`}
                  >
                    {t("Thank you for your")}
                  </p>
                  <p
                    className={`${
                      getActiveLang() === "zh" ? "disabled" : "sub-heading-two"
                    }`}
                  >
                    {t("Support")}
                  </p>
                  <p
                    className={`${
                      getActiveLang() === "zh" ? "sub-heading-two" : "disabled"
                    }`}
                  >
                    {t("Thank you for your support")}
                  </p>
                  <p
                    className="photo-link"
                    onClick={() => {
                      setIsOpenG2e(true);
                      GAEventsTracker("View G2E Photo Gallery");
                    }}
                  >
                    {t("View photo gallery")}
                  </p>
                </div>
              ) : (
                dimensions <= 750 && (
                  <div className="event-name">
                    <h2 className="heading">
                      {t("G2e asia 2019 philippines")}
                    </h2>
                    <p
                      className={`${
                        getActiveLang() === "zh" ? "disabled" : "sub-heading"
                      }`}
                    >
                      {t("Thank you for")}
                    </p>
                    <p
                      className={`${
                        getActiveLang() === "zh"
                          ? "disabled"
                          : "sub-heading-two"
                      }`}
                    >
                      {t("Your support")}
                    </p>
                    <p
                      className={`${
                        getActiveLang() === "zh"
                          ? "sub-heading-two"
                          : "disabled"
                      }`}
                    >
                      {t("Thank you for your support")}
                    </p>
                    <p
                      className="photo-link"
                      onClick={() => {
                        setIsOpenG2e(true);
                        GAEventsTracker("View G2E Photo Gallery");
                      }}
                    >
                      {t("View photo gallery")}
                    </p>
                  </div>
                )
              )}
            </div>
          </Slider>
          <Modal
            show={IcemodalIsOpen}
            onHide={() => setIsOpenIce(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Slider {...settings}>
                {imagesIce.map((items) => (
                  <div key={items.id}>
                    <img
                      className="play-btn"
                      src={require("@/" + getAssets(items.path, false, false))}
                      title="items.title"
                      alt="play"
                    />
                  </div>
                ))}
              </Slider>
            </Modal.Body>
          </Modal>

          <Modal
            show={G2EmodalIsOpen}
            onHide={() => setIsOpenG2e(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Slider {...settings}>
                {imagesG2E.map((items) => (
                  <div key={items.id}>
                    <img
                      className="play-btn"
                      src={require("@/" + getAssets(items.path, false, false))}
                      title="items.title"
                      alt="play"
                    />
                  </div>
                ))}
              </Slider>
            </Modal.Body>
          </Modal>
        </Reveal>
      </div>
    </div>
  );
};
export default Events;
