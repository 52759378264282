import "@/styles/sections/exclusive.scss";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { getAssets, getActiveLang } from "@/utils/helpers";
import Reveal from "react-awesome-reveal";
import { FADE_TOP_BOTTOM } from "@/utils/constant/keyframes";
import { INFO_IMAGES, SETTINGS } from "./info";
import useGAEventsTracker from "@/hooks/useGAEventsTracker";
import MobileExclusive from "./mobile";
import { useDimensionTracker } from "@/hooks/useDimensionsTracker.js";

const Exclusive = () => {
  const { t } = useTranslation();
  const GAEventsTracker = useGAEventsTracker("TF Exclusive Lootbox");
  const openNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const dimensions = useDimensionTracker();

  return (
    <div
      id="Lootbox"
      className={getActiveLang() === "zh" ? "exclusive-zh" : "exclusive"}
    >
      {dimensions > 750 ? (
        <div className="exclusive-wrap">
          <div className="exclusive-title">
            <div className="details g-heading">
              <Reveal keyframes={FADE_TOP_BOTTOM}>
                <p className="sub-heading">{t("Tf exclusive")}</p>
                <h2 className="heading">{t("Lootbox")}</h2>
                <p className="desc">
                  <span>{t("Lootbox games")}</span>{" "}
                  {t(
                    "are Provably Fair games that based on special algorithm that helps players to ensure the games results are truly unbiased and fair. Players can personally verify the randomness and fariness of the games using our Verification system. Giving players an opportunity to enjoy the games safely."
                  )}{" "}
                </p>
                <button
                  className="buttons"
                  onClick={() => {
                    openNewTab("/demo");
                    GAEventsTracker("/demo");
                  }}
                >
                  {t("Try demo")}
                </button>
              </Reveal>
            </div>
          </div>
          <div className="exclusive-contents">
            <Reveal keyframes={FADE_TOP_BOTTOM} delay={150}>
              <div>
                <Slider {...SETTINGS}>
                  {INFO_IMAGES.map((items) => (
                    <div className="exclusive-block" key={items.id}>
                      <div className="thumb">
                        {items.status === "New" ? (
                          <div className="new-game">{t(items.status)}</div>
                        ) : null}
                        <img
                          src={require("@/" +
                            getAssets(`${t(items.path)}`, false))}
                          title={t(items.title)}
                          alt="img"
                        />
                      </div>
                      <div className="desc">
                        <p>{t(items.info)}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </Reveal>
          </div>
        </div>
      ) : (
        dimensions <= 750 && <MobileExclusive />
      )}
    </div>
  );
};

export default Exclusive;
