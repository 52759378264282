import "@/styles/404.scss";
import "@/styles/responsive404.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getActiveLang } from "@/utils/helpers";
import { useDimensionTracker } from "../hooks/useDimensionsTracker";

const PageNotFound = () => {
  const { t } = useTranslation();
  const dimensions = useDimensionTracker();
  return (
    <div>
      {dimensions > 600 ? (
        <div className="error-container">
          <div className="error-container-wrap">
            <div
              className={` ${
                getActiveLang() === "zh" ? "zh-center" : "center"
              }`}
            >
              <span>{t("Error 404:")}</span>
            </div>
            <div
              className={` ${
                getActiveLang() === "zh" ? "zh-center" : "center"
              }`}
            >
              <span
                className={`${
                  getActiveLang() === "zh" ? "zh-size" : "sub-headings"
                }`}
              >
                {t("Sorry, we could not find the page.")}
              </span>
            </div>
            <div
              className={` ${
                getActiveLang() === "zh" ? "zh-center" : "center"
              }`}
            >
              <div className="btn-center">
                <button
                  className={`${
                    getActiveLang() === "zh" ? "zh-btn" : "btn-gotohome"
                  }`}
                >
                  <Link to="/">{t("Go to homepage")}</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        dimensions <= 600 && (
          <div className="error-container-mobile">
            <div className="error-container-mobile-wrap">
              <div
                className={` ${
                  getActiveLang() === "zh" ? "zh-center" : "center"
                }`}
              >
                <span>{t("Error 404:")}</span>
              </div>
              <div
                className={` ${
                  getActiveLang() === "zh" ? "zh-center" : "center"
                }`}
              >
                <span
                  className={`zh-size ${
                    getActiveLang() === "en" ? "zh-disabled" : ""
                  }`}
                >
                  {t("Sorry, we could not find the page.").split(",").map((text, index) => (
                      <div key={index}>
                       {text + (index === 0 ? ", " : "")}
                        {"\n"}
                      </div>
                    ))}
                </span>
                <span
                  className={`sub-headings ${
                    getActiveLang() === "zh" ? "zh-disabled" : ""
                  }`}
                >
                  {t("Sorry, we could not find the page.")}
                </span>
              </div>
              <div
                className={` ${
                  getActiveLang() === "zh" ? "zh-center" : "center"
                }`}
              >
                <div className="btn-center">
                  <button
                    className={`${
                      getActiveLang() === "zh" ? "zh-btn" : "btn-gotohome"
                    }`}
                  >
                    <Link to="/">{t("Go to homepage")}</Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default PageNotFound;
