import "@/styles/sections/gallery.scss";
import "@/styles/mobile/gallery_mobile.scss";
import { useTranslation } from "react-i18next";
import { getAssets, getActiveLang } from "@/utils/helpers";
import Reveal from "react-awesome-reveal";
import { FADE_TOP_BOTTOM } from "@/utils/constant/keyframes";
import { CSGO_IMAGES } from "./info";
import { DOTA_IMAGES } from "./info";
import { LOL_IMAGES } from "./info";
import { ARENA_IMAGES } from "./info";
import { COD_IMAGES } from "./info";
import MobileGallery from "./mobile";
import { useDimensionTracker } from "../../../hooks/useDimensionsTracker";

const Gallery = () => {
  const { t } = useTranslation();
  const dimensions = useDimensionTracker();
  return (
    <div className="gallery">
      {dimensions > 768 ? (
        <div className="gallery-wrap">
          <div className="details gl-heading">
            <Reveal keyframes={FADE_TOP_BOTTOM}>
              <p
                className={`sub-heading ${
                  getActiveLang() === "zh" ? "zh_disabled" : ""
                }`}
              >
                {t("Popular massive")}
              </p>
              <p
                className={`sub-heading-two ${
                  getActiveLang() === "zh" ? "zh_disabled" : ""
                }`}
              >
                {" "}
                {t("Multiplayer")}
              </p>
              <p
                className={`sub-heading-two ${
                  getActiveLang() === "zh" ? "" : "zh_disabled"
                }`}
              >
                {" "}
                {t("Popular massive multiplayer")}
              </p>
              <h2
                className={`${
                  getActiveLang() === "zh" ? "zh-heading" : "heading"
                }`}
              >
                {t("Esports games")}
              </h2>
            </Reveal>
            <div className="bottom-img">
              <img
                className={`${
                  getActiveLang() === "zh" ? "Tpaimage" : "Tpaimage-en"
                }`}
                src={require("@/" + getAssets("Tpa-bg.png", false, false))}
                alt="tpa"
              />
            </div>
          </div>
          <div className="gallery-contents">
            <div className="gallery-block">
              {CSGO_IMAGES.map((items) => (
                <div className="thumb down" key={items.id} loop={true}>
                  <img
                    src={require("@/" + getAssets(items.path, false, false))}
                    alt={items.title}
                    title={items.title}
                  />
                </div>
              ))}
            </div>
            <div className="gallery-block">
              {DOTA_IMAGES.map((items) => (
                <div className="thumb top" key={items.id}>
                  <img
                    src={require("@/" + getAssets(items.path, false, false))}
                    alt={items.title}
                    title={items.title}
                  />
                </div>
              ))}
            </div>
            <div className="gallery-block">
              {LOL_IMAGES.map((items) => (
                <div className="thumb down" key={items.id}>
                  <img
                    src={require("@/" + getAssets(items.path, false, false))}
                    alt={items.title}
                    title={items.title}
                  />
                </div>
              ))}
            </div>
            <div className="gallery-block">
              {ARENA_IMAGES.map((items) => (
                <div className="thumb top" key={items.id}>
                  <img
                    src={require("@/" + getAssets(items.path, false, false))}
                    alt={items.title}
                    title={items.title}
                  />
                </div>
              ))}
            </div>
            <div className="gallery-block">
              {COD_IMAGES.map((items) => (
                <div className="thumb down" key={items.id}>
                  <img
                    src={require("@/" + getAssets(items.path, false, false))}
                    alt={items.title}
                    title={items.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        dimensions <= 768 && <MobileGallery />
      )}
    </div>
  );
};
export default Gallery;
