const CSGO_IMAGES = [
  {
    id: 1,
    path: "csgo/csgo.jpg",
    title: "csgo",
  },
  {
    id: 2,
    path: "csgo/crossfire.jpg",
    title: "crossfire",
  },
  {
    id: 3,
    path: "csgo/hots.jpg",
    title: "hots",
  },
  {
    id: 4,
    path: "csgo/nba.jpg",
    title: "nba",
  },
  {
    id: 5,
    path: "csgo/csgo.jpg",
    title: "csgo",
  },
  {
    id: 6,
    path: "csgo/crossfire.jpg",
    title: "crossfire",
  },
  {
    id: 7,
    path: "csgo/hots.jpg",
    title: "hots",
  },
  {
    id: 8,
    path: "csgo/nba.jpg",
    title: "nba",
  }, 
  {
    id: 9,
    path: "csgo/csgo.jpg",
    title: "csgo",
  },
  {
    id: 10,
    path: "csgo/crossfire.jpg",
    title: "crossfire",
  },
  {
    id: 11,
    path: "csgo/hots.jpg",
    title: "hots",
  }, 
  {
    id: 12,
    path: "csgo/nba.jpg",
    title: "nba",
  },
];
const DOTA_IMAGES = [
  {
    id: 1,
    path: "dota/dota.jpg",
    title: "dota",
  },
  {
    id: 2,
    path: "dota/fifa.jpg",
    title: "fifa",
  },
  {
    id: 3,
    path: "dota/kog.jpg",
    title: "kog",
  },
  {
    id: 4,
    path: "dota/ow.jpg",
    title: "ow",
  },
  {
    id: 5,
    path: "dota/dota.jpg",
    title: "dota",
  },
  {
    id: 6,
    path: "dota/fifa.jpg",
    title: "fifa",
  },
  {
    id: 7,
    path: "dota/kog.jpg",
    title: "kog",
  },
  {
    id: 8,
    path: "dota/ow.jpg",
    title: "ow",
  },
  {
    id: 9,
    path: "dota/dota.jpg",
    title: "dota",
  },
  {
    id: 10,
    path: "dota/fifa.jpg",
    title: "fifa",
  },
  {
    id: 11,
    path: "dota/kog.jpg",
    title: "kog",
  },
  {
    id: 12,
    path: "dota/ow.jpg",
    title: "ow",
  }
];
const LOL_IMAGES = [
  {
    id: 1,
    path: "lol/lol.jpg",
    title: "lol",
  },
  {
    id: 2,
    path: "lol/fortnite.jpg",
    title: "fortnite",
  },
  {
    id: 3,
    path: "lol/wildrift.jpg",
    title: "wildrift",
  },
  {
    id: 4,
    path: "lol/pubg.jpg",
    title: "pubg",
  },
  {
    id: 5,
    path: "lol/lol.jpg",
    title: "lol",
  },
  {
    id: 6,
    path: "lol/fortnite.jpg",
    title: "fortnite",
  },
  {
    id: 7,
    path: "lol/wildrift.jpg",
    title: "wildrift",
  },
  {
    id: 8,
    path: "lol/pubg.jpg",
    title: "pubg",
  }, 
  {
    id: 9,
    path: "lol/lol.jpg",
    title: "lol",
  },
  {
    id: 10,
    path: "lol/fortnite.jpg",
    title: "fortnite",
  },
  {
    id: 11,
    path: "lol/wildrift.jpg",
    title: "wildrift",
  },
  {
    id: 12,
    path: "lol/pubg.jpg",
    title: "pubg",
  }
];
const ARENA_IMAGES = [
  {
    id: 1,
    path: "arena/aov.jpg",
    title: "aov",
  },
  {
    id: 2,
    path: "arena/gfp.jpg",
    title: "gfp",
  },
  {
    id: 3,
    path: "arena/ml.jpg",
    title: "ml",
  },
  {
    id: 4,
    path: "arena/quake.jpg",
    title: "quake",
  },
  {
    id: 5,
    path: "arena/aov.jpg",
    title: "aov",
  },
  {
    id: 6,
    path: "arena/gfp.jpg",
    title: "gfp",
  },
  {
    id: 7,
    path: "arena/ml.jpg",
    title: "ml",
  },
  {
    id: 8,
    path: "arena/quake.jpg",
    title: "quake",
  },
  {
    id: 9,
    path: "arena/aov.jpg",
    title: "aov",
  },
  {
    id: 10,
    path: "arena/gfp.jpg",
    title: "gfp",
  },
  {
    id: 11,
    path: "arena/ml.jpg",
    title: "ml",
  },
  {
    id: 12,
    path: "arena/quake.jpg",
    title: "quake",
  }
];
const COD_IMAGES = [
  {
    id: 1,
    path: "cod/cod.jpg",
    title: "cod",
  },
  {
    id: 2,
    path: "cod/hearthstone.jpg",
    title: "hearthstone",
  },
  {
    id: 3,
    path: "cod/naraka.jpg",
    title: "naraka",
  },
  {
    id: 4,
    path: "cod/r6.jpg",
    title: "r6",
  },
  {
    id: 5,
    path: "cod/cod.jpg",
    title: "cod",
  },
  {
    id: 6,
    path: "cod/hearthstone.jpg",
    title: "hearthstone",
  },
  {
    id: 7,
    path: "cod/naraka.jpg",
    title: "naraka",
  },
  {
    id: 8,
    path: "cod/r6.jpg",
    title: "r6",
  },
  {
    id: 9,
    path: "cod/cod.jpg",
    title: "cod",
  },
  {
    id: 10,
    path: "cod/hearthstone.jpg",
    title: "hearthstone",
  },
  {
    id: 11,
    path: "cod/naraka.jpg",
    title: "naraka",
  },
  {
    id: 12,
    path: "cod/r6.jpg",
    title: "r6",
  }
];
export { CSGO_IMAGES, DOTA_IMAGES, LOL_IMAGES, ARENA_IMAGES, COD_IMAGES };
