import "@/styles/demo.scss";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState, useEffect } from "react";
import i18n from "@/i18nextConf";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const DemoLink = () => {
  const { t } = useTranslation();
  const [lang, setLang] = useState("zh");
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const grecaptchaObject = window.grecaptcha;
  const [loggedIn, setLoggedInStatus] = useState(false);
  const [launchUrl, setIframeLaunchURL] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data.grecaptcharesponse = captchaRef.current.getValue();
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      data: new URLSearchParams(data),
      url: "https://api-v4.ely889.com/api/v4/callback/",
    };
    axios(options)
      .then(function (response) {
        captchaRef.current.reset();
        if (response.data.launch_url) {
          navigate("");
          setLoggedInStatus(true);
          setIframeLaunchURL(response.data.launch_url + "&lang=" + lang);
        } else {
          throw new Error("Launch url is undefined");
        }
        return response;
      })
      .catch(function (error) {
        return error;
      });

    reset();
  };

  useEffect(() => {
    setLang(window.localStorage.getItem("DEFAULT_LANGUAGE", lang));
    i18n.changeLanguage(window.localStorage.getItem("DEFAULT_LANGUAGE", lang));
  }, [lang]);

  return (
    <div id="demoForm" className="demo">
      {!loggedIn || !launchUrl ? (
        <div className="login">
          <div className="login-wrapper">
            <div className="login-header">
              <span>{t("Log in to your account")} </span>
            </div>

            <div className="error">
              {errors.username && errors.password && (
                <small className="errorMessage">
                  {t("Invalid username or password")}
                </small>
              )}
            </div>
            <div className="login-body">
              <form
                className="form"
                onSubmit={handleSubmit(onSubmit)}
                methos="POST"
                noValidate
              >
                <div className="form__group">
                  <label htmlFor="username" className="form__label username">
                    {`${t("Username or Nickname")}: `}
                  </label>
                  <input
                    className="form__field username"
                    type="text"
                    name="username"
                    {...register("username", {
                      required: {
                        value: true,
                        message: `${t("Please enter your Username.")}`,
                      },
                    })}
                    autoComplete="true"
                  />
                </div>
                <div className="form__group">
                  <label htmlFor="password" className="form__label password">
                    {`${t("Password")}: `}
                  </label>
                  <input
                    className="form__field password"
                    type="password"
                    name="password"
                    {...register("password", {
                      required: {
                        value: true,
                        message: `${t("Please enter your password.")}`,
                      },
                    })}
                    autoComplete="true"
                  />
                </div>
                <ReCAPTCHA
                  className="g-recaptcha"
                  sitekey="6LeO2zIdAAAAAEhF7d530oy4MvJ6cOzVYK7Fuzkz"
                  ref={captchaRef}
                  hl={lang}
                  grecaptcha={grecaptchaObject}
                />
                <div className="sign-in">
                  <input
                    type="submit"
                    className="button"
                    value={t("Submit")}
                    onClick={() => {
                      handleSubmit(onSubmit);
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <iframe
            id="demoIframe"
            src={launchUrl}
            frameBorder="0"
            width="100%"
            height="100%"
            scrolling="no"
            title="myFrame"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default DemoLink;
