import React from "react";
import "@/styles/footer.scss";
import { getAssets } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  if (pathname !== "/") return null;

  return (
    <footer>
      <nav>
        <ul className="nav-left">
          <li>
            <img
              src={require("@/" + getAssets("footer/Group 1225@3x.png", false))}
              alt="logo"
            />
            <div className="Copyright">
              <span>{t("Copyright")} © 2022 TFGaming.net</span>
            </div>
          </li>
        </ul>
        <ul className="nav-right">
          <li>
            <a
              href="https://access.gaminglabs.com/Certificate/Index?i=391"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="GL-1225"
                src={require("@/" +
                  getAssets("footer/GLI_Certified.png", false))}
                alt="logo"
              />
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};
export default Footer;
