
import { isMobile } from 'react-device-detect';
import { IMAGE_ASSETS } from '@/utils/constant';
import i18n from '@/i18nextConf';
import { DEFAULT_LANGUAGE } from '@/utils/constant';

export const getAssets = (val, common = false, lang = true) => {
    const device = common ? '' : isMobile ? 'mobile/' : 'desktop/';
    const language = lang ? `${getActiveLang()}/` : '';
    return IMAGE_ASSETS + `/${device}${language}${val}`;
}

export const getActiveLang = () => {
    return i18n.language ? i18n.language : DEFAULT_LANGUAGE;
}