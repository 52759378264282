import "@/styles/mobile/about_mobile.scss";
import Reveal from "react-awesome-reveal";
import { getAssets } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import {
  FADE_LEFT_RIGHT,
  FADE_RIGHT_LEFT,
  ABOUT_US_HERO,
} from "@/utils/constant/keyframes";

const MobileAboutUs = () => {
  const { t } = useTranslation();
  return (
    <div className="mobile_about-container">
      <div className="row">
        <Reveal cascade={true} keyframes={FADE_LEFT_RIGHT} className="row-img">
          <div>
            <img
              src={require("@/" + getAssets("about-us-img.png", false, false))}
              alt="About us"
              className="hero-bg"
            />
            <Reveal keyframes={ABOUT_US_HERO} delay={200} className="hero-jump">
              <img
                src={require("@/" +
                  getAssets("about-us-hero.png", false, false))}
                alt="Hero"
              />
            </Reveal>
          </div>
        </Reveal>
        <Reveal keyframes={FADE_RIGHT_LEFT} className="about-contents">
          <div className="g-heading">
            <p className="sub-heading">{t("Who we are")}</p>
            <h2 className="heading">{t("About us")}</h2>
            <p className="desc animate__bottom inview ease" data-locs>
              <span>{t("TFGaming")}</span>{" "}
              {t(
                "is developed by an expert team of Esports Developers dedicated to making TFGaming, the Leading Esports Sportbook for generations to come. Supported by a team of Vastly Experienced Bookmakers, always delivering profitable odds with margin to our partners. We constantly analyze and study players preferences in pursuit to make our Esports Sportsbook a unique and attractive product for everyone, the best gaming experience for all."
              )}{" "}
            </p>
          </div>
        </Reveal>
      </div>
    </div>
  );
};
export default MobileAboutUs;
