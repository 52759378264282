import { Link } from "react-scroll";
import { getAssets } from "@/utils/helpers";
import { useScrollSpy } from "@/hooks/useScrollSpy.js";

const HeaderLogo = () => {
  const home = ["Home"];
  const activeId = useScrollSpy(home, 100);
  return (
    <Link
      className="clickable logo-container"
      smooth
      href="#Home"
      to={`${activeId}`}
      spy
      offset={-70}
      ignoreCancelEvents={false}
    >
      <img
        onClick={() => {
          window.location.pathname = "/";
        }}
        src={require("@/" + getAssets("header-logo.png", false, false))}
        alt="logo"
      />
    </Link>
  );
};

export default HeaderLogo;
