import "@/styles/mobile/header_mobile.scss";
import { getAssets } from "@/utils/helpers";
import LangSwitcher from "./lang-switcher";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import useGAEventsTracker from "../../hooks/useGAEventsTracker.js";
import { useScrollSpy } from "@/hooks/useScrollSpy.js";

const MobileHeader = ({handleLangChange}) => {
  const { t } = useTranslation();
  const GAEventsTracker = useGAEventsTracker("Page Section");
  const ids = [
    "Home",
    "AboutUs",
    "Esports",
    "Lootbox",
    "WhyChoose",
    "Events",
    "Contact",
  ];
  const activeId = useScrollSpy(ids, 200);
  const [navbar, showNav] = useState(true);
  const homeActive = "";
  const [subBar, showSubBar] = useState(false);
  const NavBar = (event, val) => {
    showNav(event);
  };
  const SubNav = (event) => {
    showSubBar(event);
  };

  return (
    <header className="mobile">
      {navbar ? (
        <div className="cont">
          <div className="mobile-logo">
            <a href="/">
              <img
                src={require("@/" + getAssets("header-logo.png", false, false))}
                alt="logo"
              />
            </a>
          </div>
          <div className="mobile-switch">
            <LangSwitcher handleLangChange={handleLangChange} />
          </div>
          <div className="hamburger" onClick={() => NavBar(false)}>
            <img src={require("@/" + getAssets("menu.png", true))} alt="logo" />
          </div>
        </div>
      ) : (
        <div className="hamburger-menu">
          <div className="hamburger-menu-container">
            <div className="hamburger-btn" onClick={() => NavBar(true)}>
              <span>
                <IoCloseSharp />
              </span>
            </div>
            <Link
              smooth
              to={`${ids[0]}`}
              spy
              offset={-10}
              onClick={() => {
                NavBar(true);
                GAEventsTracker("Home Section");
              }}
              className={ids[0] === activeId && homeActive ? "active" : ""}
            >
              {t("Home")}
            </Link>
            <Link
              smooth
              to={`${ids[1]}`}
              spy
              offset={-5}
              onClick={() => {
                NavBar(true);
                GAEventsTracker("About Us Section");
              }}
              className={
                ids[1] === activeId || ids[1] === homeActive ? "active" : ""
              }
            >
              {t("About")}
            </Link>
            {!subBar ? (
              <Link to="/" onClick={() => SubNav(true)}>
                {t("Products")}
                <span className="span-arrows">
                  <IoIosArrowUp />
                </span>
              </Link>
            ) : (
              <div>
                <Link
                  to="/"
                  onClick={() => SubNav(false)}
                  className={
                    ids[2] === activeId ||
                    ids[3] === activeId ||
                    ids[2] === homeActive
                      ? "active"
                      : ""
                  }
                >
                  {t("Products")}
                  <span className="span-arrows">
                    <IoIosArrowDown />
                  </span>
                </Link>
                <div className="nav-sub">
                  <Link
                    smooth
                    to={`${ids[2]}`}
                    spy
                    offset={-1}
                    onClick={() => {
                      NavBar(true);
                      GAEventsTracker("Esports Section");
                    }}
                    className={
                      ids[2] === activeId || ids[2] === homeActive
                        ? "active"
                        : ""
                    }
                  >
                    {t("Esports")}
                  </Link>
                  <Link
                    smooth
                    to={`${ids[3]}`}
                    spy
                    offset={10}
                    onClick={() => {
                      NavBar(true);
                      GAEventsTracker("Lootbox Section");
                    }}
                    className={
                      ids[3] === activeId || ids[3] === homeActive
                        ? "active"
                        : ""
                    }
                  >
                    {t("Lootbox")}
                  </Link>
                </div>
              </div>
            )}

            <Link
              smooth
              to={`${ids[4]}`}
              spy
              offset={11}
              onClick={() => {
                NavBar(true);
                GAEventsTracker("Why Choose Us Section");
              }}
              className={
                ids[4] === activeId || ids[4] === homeActive ? "active" : ""
              }
            >
              {t("Why choose us")}
            </Link>
            <Link
              smooth
              to={`${ids[5]}`}
              spy
              offset={12}
              onClick={() => {
                NavBar(true);
                GAEventsTracker("Events Section");
              }}
              className={
                ids[5] === activeId || ids[5] === homeActive ? "active" : ""
              }
            >
              {t("Events")}
            </Link>
            <Link
              smooth
              to={`${ids[6]}`}
              spy
              offset={13}
              onClick={() => {
                NavBar(true);
                GAEventsTracker("Contact Us Section");
              }}
              className={
                ids[6] === activeId || ids[6] === homeActive ? "active" : ""
              }
            >
              {t("Contact us")}
            </Link>
            <div className="hamburger-img-bottom">
              <Link smooth to="/">
                <img
                  src={require("@/" +
                    getAssets("header-logo.png", false, false))}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default MobileHeader;
