import { useState, useLayoutEffect } from "react";

const clamp = (value = 0) => Math.max(0, value);

const isBetween = (value = 0, floor = 0, ceil = 0) =>
  value >= floor && value <= ceil;

export const useScrollSpy = (ids = [], offset = 0) => {
  const [activeId, setActiveId] = useState("Home");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "Home");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  window.onload = (e) => {
    window.addEventListener("load", setActiveId("Home"));
    window.location.reload(false)
    e.preventDefault();
    return () => {
      window.removeEventListener("load", setActiveId("Home"));
    };
  };
  return activeId;
};
