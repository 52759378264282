import { getAssets } from "@/utils/helpers";
import { IoCloseSharp } from "react-icons/io5";
import "@/styles/modal.scss";
import { useLocation } from "react-router-dom";
import { useDimensionTracker } from "@/hooks/useDimensionsTracker.js";

const ModalWarning = ({ modalIsOpen, setModalIsOpen }) => {
  const dimensions = useDimensionTracker();
  const { pathname } = useLocation();
  if (pathname !== "/") return null;
  return (
    <div className={modalIsOpen ? "modal_warning" : "display-none"}>
      {dimensions > 700 && modalIsOpen ? (
        <div className="modal_warning-open">
          <div id="popup">
            <div className="close-btn" onClick={() => setModalIsOpen(false)}>
              <IoCloseSharp />
            </div>
            <img
              src={require("@/" + getAssets("warning modal.png", false))}
              alt="modal"
            />
          </div>
        </div>
      ) : (
        dimensions <= 700 &&
        modalIsOpen && (
          <div className="modal_warning-open">
            <div id="popup">
              <div className="close-btn" onClick={() => setModalIsOpen(false)}>
                <IoCloseSharp />
              </div>
              <img
                src={require("@/" +
                  getAssets("modal_mobile.png", true))}
                alt="modal"
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default ModalWarning;
