import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { FLAG_LIST } from "@/utils/constant";
import { getAssets } from "@/utils/helpers";
import i18n from "@/i18nextConf";
import { useTranslation } from "react-i18next";
import useGAEventsTracker from "../../hooks/useGAEventsTracker";

const LangSwitcher = ({ handleLangChange }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState("zh");

  const applyLang = (e, lang) => {
    e.preventDefault();
    handleLangChange();
    setLang(lang);
    i18n.changeLanguage(lang);
    window.localStorage.setItem("DEFAULT_LANGUAGE", lang);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.localStorage.getItem("DEFAULT_LANGUAGE", lang);
    setLang(window.localStorage.getItem("DEFAULT_LANGUAGE", lang));
    i18n.changeLanguage(window.localStorage.getItem("DEFAULT_LANGUAGE", lang));
  }, [lang]);

  const GAEventsTracker = useGAEventsTracker("Language");
  return (
    <ul className={"lang-switcher " + lang}>
      {FLAG_LIST.map((flag) => (
        <li key={flag.id}>
          <Link
            className={"clickable " + (lang === flag.id ? "active" : "")}
            to="/"
            smooth
            onClick={(e) => {
              applyLang(e, flag.id);
              GAEventsTracker("Translate to: " + flag.id);
            }}
          >
            <img
              src={require("@/" + getAssets(flag.icon, false, false))}
              alt={flag.label}
            />
            <span>{t(flag.label)}</span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default LangSwitcher;
