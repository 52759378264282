import { keyframes } from "@emotion/react";

const FADE_LEFT_RIGHT = keyframes`
    from {
        opacity: 0;
        transform: translate3d(-100px, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

const FADE_RIGHT_LEFT = keyframes`
    from {
        opacity: 0;
        transform: translate3d(100px, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

const FADE_TOP_BOTTOM = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -100px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

const FADE_BOTTOM_TOP = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

const ABOUT_US_HERO = keyframes`
    from {
        opacity: 0;
        transform: translate3d(-300px, -100px, 0) scale(0.1);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
`;

const MENU_FADE_SLIDE_DOWN = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export {
    FADE_LEFT_RIGHT,
    FADE_RIGHT_LEFT,
    FADE_TOP_BOTTOM,
    FADE_BOTTOM_TOP,
    ABOUT_US_HERO,
    MENU_FADE_SLIDE_DOWN,
}