import "@/styles/mobile/exclusive_mobile.scss";
import { useTranslation } from "react-i18next";
import { getAssets } from "@/utils/helpers";
import Reveal from "react-awesome-reveal";
import { FADE_TOP_BOTTOM } from "@/utils/constant/keyframes";
import { INFO_IMAGES } from "./info";
import useGAEventsTracker from "@/hooks/useGAEventsTracker";

const MobileExclusive = () => {
  const { t } = useTranslation();
  const GAEventsTracker = useGAEventsTracker("TF Exclusive Lootbox");
  const openNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="mobile_exclusive-containers">
      <div className="mobile_exclusive-wrap">
        <div className="">
          <div className="title">
            <Reveal keyframes={FADE_TOP_BOTTOM}>
              <p className="sub-heading">{t("Tf exclusive")}</p>
              <h2 className="heading">{t("Lootbox")}</h2>
              <p className="desc">
                <span>{t("Lootbox games")}</span>{" "}
                {t(
                  "are Provably Fair games that based on special algorithm that helps players to ensure the games results are truly unbiased and fair. Players can personally verify the randomness and fariness of the games using our Verification system. Giving players an opportunity to enjoy the games safely."
                )}{" "}
              </p>
              <button
                className="buttons"
                onClick={() => {
                  openNewTab("/demo");
                  GAEventsTracker("/demo");
                }}
              >
                {t("Try demo")}
              </button>
            </Reveal>
          </div>
        </div>
        <div className="contents">
          <ul>
            {INFO_IMAGES.map((items) => (
              <li key={items.id}>
                <div className="card">
                  {items.status === "New" ? (
                    <div className="new-game">{t(items.status)}</div>
                  ) : null}
                  <img
                    src={require("@/" + getAssets(`${t(items.path)}`, false))}
                    title={t(items.title)}
                    alt="img"
                  />
                </div>
                <div className="desc">
                  <p>{t(items.info)}</p>
                </div>
              </li>
            ))}
          </ul>
          {/* <div>
              <Slider {...SETTINGS}>
                {INFO_IMAGES.map((items) => (
                  <div className="block" key={items.id}>
                    <div className="thumb">
                      {items.status === "New" ? (
                        <div className="new-game">{t(items.status)}</div>
                      ) : null}
                      <img
                        src={require("@/" +
                          getAssets(`${t(items.path)}`, false))}
                        title={t(items.title)}
                        alt="img"
                      />
                    </div>
                    <div className="desc">
                      <p>{t(items.info)}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default MobileExclusive;
