const INFO_IMAGES = [
  {
    id: 1,
    status: "New",
    path: "exclusive-dotaAttack.png",
    title: "Dota 2 attack",
    info: "It’s a battle between your hero (Radiant) and the enemy (Dire). Have full control of your payout and set your hero’s attack power to beat the enemy.",
  },
  {
    id: 2,
    status: "New",
    path: "exclusive-lolcouple.png",
    title: "777 couple",
    info: "Enter the BONUS round and receive up to 177x payout! Just guess the sum of the male and female cards and wait for the 777 couples to match.",
  },
  {
    id: 3,
    status: "New",
    path: "exclusive-csgoflip.png",
    title: "Cs:go flip",
    info: "Flip the coin and receive payout instantly. Pick your side. Are you Terrorist or Counter-Terrorist?",
  },
  {
    id: 4,
    status: "New",
    path: "exclusive-loltower.png",
    title: "Lol tower",
    info: "Progress to the next level every time you successfully avoid the bomb. Clear the highest level to become a Champion and get 160x payout!",
  },
  {
    id: 5,
    status: "New",
    path: "exclusive-csgodefuse.png",
    title: "Cs:go defuse",
    info: "Win up to 100x payout! Your mission is to prevent an explosion by defusing the bomb. The clock is ticking. Don’t let the terrorists win!",
  },
  {
    id: 6,
    status: "",
    path: "exclusive-kogdraw.png",
    title: "Kog draw",
    info: "KOG DRAW is a crypto-oriented game where your task is to predict the attributes (Gender, Range, Class) of the next Hero Card.",
  },
  {
    id: 7,
    status: "",
    path: "exclusive-dotatreasure.png",
    title: "Dota 2 treasure",
    info: "With a treasure lootbox containing Rarity Skins, increase your chances of winning a lucky payout — from 50x to 500x!",
  },
  {
    id: 8,
    status: "",
    path: "exclusive-loldraft.png",
    title: "Lol draft",
    info: "LOL Draft simulates from Blind Ban Drafting; your task is to predict the champions that will be picked for BAN /  TOP / JUNGLE / MID / BOT / SUPPORT.",
  }
];

const SETTINGS = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
  ],
};
export { INFO_IMAGES, SETTINGS };
