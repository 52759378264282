import "@/styles/sections/contact.scss";
import "@/styles/mobile/contact_mobile.scss";
import "react-phone-number-input/style.css";

import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { getAssets } from "@/utils/helpers";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import { useForm, Controller } from "react-hook-form";
import React, { useState, useRef } from "react";
import useGAEventsTracker from "../../hooks/useGAEventsTracker";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const { t } = useTranslation();
  const form_email = useRef();
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const [number, setCountries] = useState("");
  const [isPhone, setPhone] = useState(false);
  const [isTelegramSkype, setTelegramSkype] = useState(false);

  const [Success, setShowSuccess] = useState(false);
  const [MainContact, setShowMain] = useState(true);

  const onSubmit = (data) => {
    if (data.phone.length <= 8) {
      setPhone(true);
      return;
    }
    if ((data.skype.length <= 0 && data.telegram.length <= 0)) {
      setTelegramSkype(true);
      return;
    }
    setShowSuccess(true);
    setShowMain(false);
    setCountries("");
    data.received = "TFgaming";
    emailjs
      .sendForm(
        "service_tfgaming_contact",
        "template_rfkq4ls",
        form_email.current,
        "5ZD_aYGqr6EsG1Y3W"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    reset();
  };

  const goBackhome = () => {
    setShowMain(true);
    setShowSuccess(false);
    setPhone(false);
    setTelegramSkype(false);
  };

  const GAEventsTracker = useGAEventsTracker("External Links");

  return (
    <div id="Contact" className="contact-box">
      <div className="contact-box-wrap">
        <div className="contact-box-wrap-left">
          <div className="div-inside-image">
            <img
              className="inside-image"
              src={require("@/" +
                getAssets("contact/mockup-all.png", false, false))}
              alt="mockup all"
            />
            <div className="social_media">
              <h1>{t("Click to send a direct message")}</h1>
              <div className="contacts">
                <ul>
                  <li>
                    <a
                      href="https://t.me/tfgaming888"
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) =>
                        GAEventsTracker(
                          "https://api.whatsapp.com/send/?phone=639666011888&text&type=phone_number&app_absent=0"
                        )
                      }
                    >
                      <img
                        src={require("@/" +
                          getAssets("contact/skype.png", false, false))}
                        alt="whatsapp"
                        title="whatsapp"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://api.whatsapp.com/send/?phone=639666011888&text&type=phone_number&app_absent=0"
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) =>
                        GAEventsTracker("https://t.me/tfgaming888")
                      }
                    >
                      <img
                        src={require("@/" +
                          getAssets("contact/telegram.png", false, false))}
                        alt="telegram"
                        title="telegram"
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <p className="site_number">+63 9666011888</p>
            </div>
          </div>
        </div>
        <div className="contact-box-wrap-right">
          {MainContact && (
            <form ref={form_email} onSubmit={handleSubmit(onSubmit)} noValidate>
              <h2 className="heading">{t("Contact us")}</h2>
              {errors.name && (
                <small className="errorMessage">{t(errors.name.message)}</small>
              )}
              <input
                type="text"
                name="name"
                className={`form-control ${errors.name && "invalid"}`}
                {...register("name", {
                  required: {
                    value: true,
                    message: "Please enter your name.",
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter your name.",
                  },
                })}
                onKeyUp={() => {
                  trigger("name");
                }}
                placeholder={t("Name*")}
                required
              ></input>
              {errors.email && (
                <small className="errorMessage">
                  {t(errors.email.message)}
                </small>
              )}
              <input
                type="text"
                name="email"
                className={`form-control ${errors.email && "invalid"}`}
                {...register("email", {
                  required: {
                    value: true,
                    message: "Please enter your email address.",
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Please enter your email address.",
                  },
                })}
                onKeyUp={() => {
                  trigger("email");
                }}
                placeholder={t("Email*")}
                required
              ></input>
              <div className="details">
                {(isPhone || errors.phone) && (
                  <small className="errorMessage">
                    {t("Please enter your contact number")}
                  </small>
                )}
                {((!isPhone && !errors.phone) && (isTelegramSkype || errors.telegram || errors.skype)) && (
                  <small className="errorMessage">
                    {t("You must fill out either skype or telegram")}
                  </small>
                )}
                <div
                  name="receive"
                  id="received"
                  className={`form-phone ${((isPhone || errors.phone) || (isTelegramSkype || errors.telegram || errors.skype)) && "invalid"}`}
                >
                  <label>{t("Contact number (please add country code)")}</label>
                  <div className="phone">
                    <div className="container">
                      <div className="inline">
                        <Controller
                          name="phone"
                          control={control}
                          render={({ field: { ...field } }) => (
                            <PhoneInput
                              {...field}
                              countryCallingCodeEditable={false}
                              defaultCountry="CN"
                              international
                              value={number && formatPhoneNumberIntl(number)}
                              {...register("phone", {
                                validate: {
                                  rule1(value) {
                                    return (/^\+(\d{1,4})\s(\d+(\s\d+)*)$/).test(value)
                                  },
                                },
                                maxLength: {
                                  value: 20,
                                },
                                minLength: {
                                  value: 8,
                                },
                                pattern: {
                                  value: "({{999}}) {{999}}-{{9999}}",
                                },
                              })}
                              onChange={(text) => {
                                setCountries(text);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="skypeTelegram">
                      <input
                        name="skype"
                        className={`width-size ${errors.skype && "invalid"}`}
                        {...register("skype", {
                          required: false,
                          validate: {
                            rule1(value) {
                              if (value !== "") return setTelegramSkype(false);
                              else if (getValues("telegram") === "" && value === "") return setTelegramSkype(true);
                            },
                          },
                          minLength: {
                            value: 6,
                          },
                          maxLength: {
                            value: 32,
                          },
                        })}
                        type="text"
                        placeholder={t("Skype")}
                        required
                      ></input>
                      <input
                        name="telegram"
                        className={`width-size ${errors.telegram && "invalid"}`}
                        {...register("telegram", {
                          required: false,
                          validate: {
                            rule1(value) {
                              if (value !== "") return setTelegramSkype(false);
                              else if (getValues("skype") === "" && value === "") return setTelegramSkype(true);
                            },
                          },
                          minLength: {
                            value: 5,
                          },
                        })}
                        type="text"
                        placeholder={t("Telegram")}
                        required
                      ></input>
                    </div>
                  </div>
                </div>
                {errors.company && (
                  <small className="red">
                    {t("Please enter your company.")}
                  </small>
                )}
                <input
                  type="text"
                  name="company"
                  className="form-control"
                  {...register("company", {
                    required: {
                      value: false,
                    },
                  })}
                  placeholder={t("Company")}
                ></input>
                <div className="enter-message">
                  {errors.message && (
                    <small className="red">
                      {t("Please enter your message.")}
                    </small>
                  )}
                  <textarea
                    {...register("message", {
                      required: true,
                      minLength: {
                        value: 2,
                        message: `${t("Please enter your message.")}`,
                      },
                    })}
                    onKeyUp={() => {
                      trigger("message");
                    }}
                    className={`form-control ${errors.message && "invalid"}`}
                    placeholder={t("Your message*")}
                    name="message"
                  ></textarea>
                  {errors.message && <span className="red"></span>}
                </div>
                <div className="btn-div">
                  <button
                    type="submit"
                    title={t("Send message")}
                    onClick={() => {
                      handleSubmit(onSubmit);
                      GAEventsTracker("Click Send Message");
                    }}
                  >
                    {t("Send message")}
                  </button>
                </div>
              </div>
            </form>
          )}
          {Success && (
            <div className="div_succes">
              <h2 className="heading">{t("Thanks!")}</h2>
              <h2 id="message">{t("Your message has been sent.")}</h2>
              <h2 id="message">{t("We’ll get in touch with you shortly.")}</h2>
              <button
                className="success_btn"
                onClick={() => {
                  goBackhome();
                  GAEventsTracker("Home Page");
                }}
              >
                {t("Okay, got it.")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
