import "@/styles/sections/banner.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getAssets, getActiveLang } from "@/utils/helpers";
import {
  BANNER_1,
  BANNER_2,
  BANNER_3,
  BANNER_4,
  BANNER_3IMG,
  BANNER_4IMG,
} from "./info";
import { useTranslation } from "react-i18next";
import MobileBanner from "./mobile";
import { useDimensionTracker } from "@/hooks/useDimensionsTracker.js";

const Banner = () => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
  };
  const dimensions = useDimensionTracker();

  return (
    <div id="Home" className="banner">
      {dimensions > 600 ? (
        <div>
          <Slider {...settings}>
            <div className="banner-1">
              <a
                className="cert-area"
                href="https://access.gaminglabs.com/Certificate/Index?i=391"
                alt="GLC certified"
                target="_blank"
                rel="noreferrer"
              >
                <i class="fa fa-phone" aria-hidden="true"></i>
              </a>

              <img
                src={require("@/" + getAssets("banner-1.jpg", false, false))}
                alt="cover"
                className="cover"
              />
              {BANNER_1.map((items) => (
                <div className="contents slide-1" key={items.id}>
                  <img
                    src={require("@/" + getAssets(`${t(items.path)}`, false))}
                    alt={t(items.title)}
                    title={t(items.title)}
                    className={items.class}
                  />
                </div>
              ))}
            </div>
            <div>
              <img
                src={require("@/" + getAssets("banner-2.jpg", false, false))}
                alt="banner"
                className="cover"
              />
              {BANNER_2.map((items) => (
                <div
                  className={
                    getActiveLang() === "zh"
                      ? "contents slide-2-zh"
                      : "contents slide-2"
                  }
                  key={items.id}
                >
                  <img
                    src={require("@/" + getAssets(`${t(items.path)}`, false))}
                    alt={t(items.title)}
                    title={t(items.title)}
                    className={items.class}
                  />
                </div>
              ))}
            </div>
            <div>
              <img
                src={require("@/" + getAssets("banner-3.jpg", false, false))}
                alt="banner"
                className="cover"
              />
              {BANNER_3.map((items) => (
                <div className="contents slide-3" key={items.id}>
                  <img
                    src={require("@/" + getAssets(`${t(items.path)}`, false))}
                    alt={t(items.title)}
                    title={t(items.title)}
                    className={items.class}
                  />
                </div>
              ))}
              {BANNER_3IMG.map((items) => (
                <div className="contents slide-3" key={items.id}>
                  <img
                    src={require("@/" +
                      getAssets(`${t(items.path)}`, false, false))}
                    alt={t(items.title)}
                    title={t(items.title)}
                    className={items.class}
                  />
                </div>
              ))}
            </div>
            <div>
              <img
                src={require("@/" + getAssets("banner-4.jpg", false, false))}
                alt="banner"
                className="cover"
              />
              <div className="contents slide-4">
                <img
                  src={require("@/" + getAssets("banner-4-title.png", false))}
                  alt={t("TFGaming")}
                  title={t("TFGaming")}
                  className={getActiveLang() === "zh" ? "zh-title" : "title"}
                />
              </div>
              {BANNER_4.map((items) => (
                <div className="contents slide-4" key={items.id}>
                  <img
                    src={require("@/" + getAssets(`${t(items.path)}`, false))}
                    alt={t(items.title)}
                    title={t(items.title)}
                    className={items.class}
                  />
                </div>
              ))}
              {BANNER_4IMG.map((items) => (
                <div className="contents slide-4" key={items.id}>
                  <img
                    src={require("@/" +
                      getAssets(`${t(items.path)}`, false, false))}
                    alt={t(items.title)}
                    title={t(items.title)}
                    className={items.class}
                  />
                </div>
              ))}
            </div>
          </Slider>
          <img
            src={require("@/" + getAssets("banner-icon.png", false, false))}
            alt="scroll"
            className="scroll-icon"
          />
        </div>
      ) : (
        dimensions <= 600 && <MobileBanner />
      )}
    </div>
  );
};

export default Banner;
