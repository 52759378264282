const SERVICES_1 = [
  {
    id: 1,
    path: "services-1.png",
    title: "Seamless integration",
    desc_first: "Iframe solution",
    desc_sec: "Api odds data",
    delay: 0,
  },
  {
    id: 2,
    path: "services-2.png",
    title: "Powerful backoffice tool",
    desc_first: "Members behavior and reports",
    desc_sec: "Real-time monitoring of performance",
    delay: 150,
  },
  {
    id: 3,
    path: "services-3.png",
    title: "Language & currencies",
    desc_first: "Fully localized into multiple language and currencies",
    desc_sec: "Multiple wallet transfer",
    delay: 300,
  },
  {
    id: 4,
    path: "services-4.png",
    title: "Customer service b2b",
    desc_first: "24/7 Customer service for support at any hour",
    desc_sec: "",
    delay: 450,
  },
];
const SERVICES_2 = [
  {
    id: 5,
    path: "services-5.png",
    title: "Extensive Choices of Esports Markets",
    desc_first: "Most markets choices for prematch and inplay",
    desc_sec: "Innovative markets choices",
    delay: 450,
  },
  {
    id: 6,
    path: "services-6.png",
    title: "Product certification",
    desc_first:
      "Obtained GLI gaming certification standard, trusted and fair system",
    desc_sec: "Stable and guaranteed profit",
    delay: 300,
  },
  {
    id: 7,
    path: "services-7.png",
    title: "Leading the market",
    desc_first: "Most number of inplay market offering",
    desc_sec: "Highest uptime for inplay markets",
    delay: 150,
  },
  {
    id: 8,
    path: "services-8.png",
    title: "Excellent betting experience",
    desc_first: "Fast and Stable system, double betting speed",
    desc_sec: "First class security & protection system",
    delay: 150,
  },
];

export { SERVICES_1, SERVICES_2 };
