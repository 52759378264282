import "./styles/index.scss";
import Routers from "./router/index.js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
function App() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t(
      "TFGaming asia’s leading esports platform provider esports for all. success start from us."
    );
  });
  return <Routers />;
}

export default App;
